/* eslint-disable import/prefer-default-export */
export const InvestmentDummy = [
  {
    date: '2023-11-5',
    value: 2000,
  },
  {
    date: '2023-11-30',
    value: 8592.77,
  },
  {
    date: '2023-12-15',
    value: 4000.49,
  },
  {
    date: '2023-12-31',
    value: 7438.6,
  },
  {
    date: '2024-01-15',
    value: 6002.49,
  },
  {
    date: '2024-01-30',
    value: 4072.49,
  },
];

export const PieChartDummy = [
  {
    name: 'Back to Company',
    value: 400,
  },
  {
    name: 'Back to Company',
    value: 300,
  }, {
    name: 'Back to Company',
    value: 500,
  }, {
    name: 'Back to Company',
    value: 480,
  },
];

export const stakingByAsset = [
  {
    date: '2024-10-17',
    assets: [
      {
        asset: 'SOL',
        value: 20,
      },
      {
        asset: 'SUI',
        value: 150,
      },
      {
        asset: 'ETH',
        value: 70,
      },
    ],
  },
  {
    date: '2024-10-18',
    assets: [
      {
        asset: 'SOL',
        value: 70,
      },
      {
        asset: 'SUI',
        value: 20,
      },
      {
        asset: 'ETH',
        value: 10,
      },
    ],
  },
  {
    date: '2024-10-19',
    assets: [
      {
        asset: 'SOL',
        value: 0,
      },
      {
        asset: 'SUI',
        value: 10,
      },
      {
        asset: 'ETH',
        value: 200,
      },
    ],
  },
  {
    date: '2024-10-20',
    assets: [
      {
        asset: 'SOL',
        value: 160,
      },
      {
        asset: 'SUI',
        value: 90,
      },
      {
        asset: 'ETH',
        value: 400,
      },
    ],
  },
  {
    date: '2024-10-21',
    assets: [
      {
        asset: 'SOL',
        value: 80,
      },
      {
        asset: 'SUI',
        value: 10,
      },
      {
        asset: 'ETH',
        value: 180,
      },
    ],
  },
  {
    date: '2024-10-22',
    assets: [
      {
        asset: 'SOL',
        value: 600,
      },
      {
        asset: 'SUI',
        value: 210,
      },
      {
        asset: 'ETH',
        value: 140,
      },
    ],
  },
  {
    date: '2024-10-23',
    assets: [
      {
        asset: 'SOL',
        value: 10,
      },
      {
        asset: 'SUI',
        value: 350,
      },
      {
        asset: 'ETH',
        value: 700,
      },
    ],
  },
  {
    date: '2024-10-24',
    assets: [
      {
        asset: 'SOL',
        value: 300,
      },
      {
        asset: 'SUI',
        value: 650,
      },
      {
        asset: 'ETH',
        value: 20,
      },
    ],
  },
  {
    date: '2024-10-25',
    assets: [
      {
        asset: 'SOL',
        value: 600,
      },
      {
        asset: 'SUI',
        value: 700,
      },
      {
        asset: 'ETH',
        value: 20,
      },
    ],
  },
  {
    date: '2024-10-26',
    assets: [
      {
        asset: 'SOL',
        value: 200,
      },
      {
        asset: 'SUI',
        value: 300,
      },
      {
        asset: 'ETH',
        value: 800,
      },
    ],
  },
  {
    date: '2024-10-27',
    assets: [
      {
        asset: 'SOL',
        value: 800,
      },
      {
        asset: 'SUI',
        value: 600,
      },
      {
        asset: 'ETH',
        value: 400,
      },
    ],
  },
  {
    date: '2024-10-28',
    assets: [
      {
        asset: 'SOL',
        value: 999,
      },
      {
        asset: 'SUI',
        value: 250,
      },
      {
        asset: 'ETH',
        value: 900,
      },
    ],
  },
  {
    date: '2024-10-29',
    assets: [
      {
        asset: 'SOL',
        value: 780,
      },
      {
        asset: 'SUI',
        value: 230,
      },
      {
        asset: 'ETH',
        value: 555,
      },
    ],
  },
  {
    date: '2024-10-30',
    assets: [
      {
        asset: 'SOL',
        value: 450,
      },
      {
        asset: 'SUI',
        value: 380,
      },
      {
        asset: 'ETH',
        value: 840,
      },
    ],
  },
  {
    date: '2024-10-31',
    assets: [
      {
        asset: 'SOL',
        value: 840,
      },
      {
        asset: 'SUI',
        value: 480,
      },
      {
        asset: 'ETH',
        value: 250,
      },
    ],
  },
  {
    date: '2024-11-01',
    assets: [
      {
        asset: 'SOL',
        value: 120,
      },
      {
        asset: 'SUI',
        value: 560,
      },
      {
        asset: 'ETH',
        value: 890,
      },
    ],
  },
  {
    date: '2024-11-02',
    assets: [
      {
        asset: 'SOL',
        value: 450,
      },
      {
        asset: 'SUI',
        value: 680,
      },
      {
        asset: 'ETH',
        value: 444,
      },
    ],
  },
  {
    date: '2024-11-03',
    assets: [
      {
        asset: 'SOL',
        value: 190,
      },
      {
        asset: 'SUI',
        value: 700,
      },
      {
        asset: 'ETH',
        value: 690,
      },
    ],
  },
  {
    date: '2024-11-04',
    assets: [
      {
        asset: 'SOL',
        value: 444,
      },
      {
        asset: 'SUI',
        value: 999,
      },
      {
        asset: 'ETH',
        value: 359,
      },
    ],
  },
  {
    date: '2024-11-05',
    assets: [
      {
        asset: 'SOL',
        value: 780,
      },
      {
        asset: 'SUI',
        value: 340,
      },
      {
        asset: 'ETH',
        value: 550,
      },
    ],
  },
  {
    date: '2024-11-06',
    assets: [
      {
        asset: 'SOL',
        value: 340,
      },
      {
        asset: 'SUI',
        value: 800,
      },
      {
        asset: 'ETH',
        value: 129,
      },
    ],
  },
  {
    date: '2024-11-07',
    assets: [
      {
        asset: 'SOL',
        value: 450,
      },
      {
        asset: 'SUI',
        value: 800,
      },
      {
        asset: 'ETH',
        value: 120,
      },
    ],
  },
  {
    date: '2024-11-08',
    assets: [
      {
        asset: 'SOL',
        value: 100,
      },
      {
        asset: 'SUI',
        value: 340,
      },
      {
        asset: 'ETH',
        value: 670,
      },
    ],
  },
  {
    date: '2024-11-09',
    assets: [
      {
        asset: 'SOL',
        value: 459,
      },
      {
        asset: 'SUI',
        value: 888,
      },
      {
        asset: 'ETH',
        value: 123,
      },
    ],
  },
  {
    date: '2024-11-10',
    assets: [
      {
        asset: 'SOL',
        value: 670,
      },
      {
        asset: 'SUI',
        value: 900,
      },
      {
        asset: 'ETH',
        value: 530,
      },
    ],
  },
  {
    date: '2024-11-11',
    assets: [
      {
        asset: 'SOL',
        value: 340,
      },
      {
        asset: 'SUI',
        value: 670,
      },
      {
        asset: 'ETH',
        value: 550,
      },
    ],
  },
  {
    date: '2024-11-10',
    assets: [
      {
        asset: 'SOL',
        value: 560,
      },
      {
        asset: 'SUI',
        value: 900,
      },
      {
        asset: 'ETH',
        value: 220,
      },
    ],
  },
  {
    date: '2024-11-11',
    assets: [
      {
        asset: 'SOL',
        value: 440,
      },
      {
        asset: 'SUI',
        value: 670,
      },
      {
        asset: 'ETH',
        value: 888,
      },
    ],
  },
  {
    date: '2024-11-12',
    assets: [
      {
        asset: 'SOL',
        value: 299,
      },
      {
        asset: 'SUI',
        value: 900,
      },
      {
        asset: 'ETH',
        value: 400,
      },
    ],
  },
  {
    date: '2024-11-13',
    assets: [
      {
        asset: 'SOL',
        value: 340,
      },
      {
        asset: 'SUI',
        value: 890,
      },
      {
        asset: 'ETH',
        value: 230,
      },
    ],
  },
  {
    date: '2024-11-14',
    assets: [
      {
        asset: 'SOL',
        value: 400,
      },
      {
        asset: 'SUI',
        value: 150,
      },
      {
        asset: 'ETH',
        value: 450,
      },
    ],
  },
];

export const dummyStakingSubRowData = [
  {
    date: '2024-10-01',
    asset: 'SOL',
    stakingBalanceInAsset: 8206.075406392,
    location: 'Coinbase',
    pendingRewards: 0,
    portfolioPercentage: 0.7619612916069081,
    rewardsPerWeek: 2823.4118419854804,
    rewardsPerWeekAsset: 13.4104,
    stakingBalance: 1727698.6874838176,
    trader: 'james giancotti',
  },
  {
    date: '2024-09-01',
    asset: 'SOL',
    stakingBalanceInAsset: 8148.834406392,
    location: 'Coinbase',
    pendingRewards: 0,
    portfolioPercentage: 0.09419612916069081,
    rewardsPerWeek: 2823.4118419854804,
    rewardsPerWeekAsset: 13.4104,
    stakingBalance: 1727698.6874838176,
    trader: 'james giancotti',
    rewardsEarned: 148.834567,
  },
  {
    date: '2024-10-01',
    asset: 'SUI',
    stakingBalanceInAsset: 6473.075406392,
    location: 'Coinbase',
    pendingRewards: 0,
    portfolioPercentage: 0.7619612916069081,
    rewardsPerWeek: 2823.4118419854804,
    rewardsPerWeekAsset: 13.4104,
    stakingBalance: 1727698.6874838176,
    trader: 'james giancotti',
  },
  {
    date: '2024-09-01',
    asset: 'SUI',
    stakingBalanceInAsset: 3456.834406392,
    location: 'Coinbase',
    pendingRewards: 0,
    portfolioPercentage: 0.09419612916069081,
    rewardsPerWeek: 2823.4118419854804,
    rewardsPerWeekAsset: 13.4104,
    stakingBalance: 1727698.6874838176,
    trader: 'james giancotti',
    rewardsEarned: 148.834567,
  },
  {
    date: '2024-10-01',
    asset: 'ETH',
    stakingBalanceInAsset: 6473.075406392,
    location: 'Coinbase',
    pendingRewards: 0,
    portfolioPercentage: 0.7619612916069081,
    rewardsPerWeek: 2823.4118419854804,
    rewardsPerWeekAsset: 13.4104,
    stakingBalance: 1727698.6874838176,
    trader: 'james giancotti',
  },
  {
    date: '2024-09-01',
    asset: 'ETH',
    stakingBalanceInAsset: 3456.834406392,
    location: 'Coinbase',
    pendingRewards: 0,
    portfolioPercentage: 0.09419612916069081,
    rewardsPerWeek: 2823.4118419854804,
    rewardsPerWeekAsset: 13.4104,
    stakingBalance: 1727698.6874838176,
    trader: 'james giancotti',
    rewardsEarned: 148.834567,
  },
];

export const dummyColdWalletData = [
  {
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
    address: '2820fe1a04074c5ea598d9ef82700552',
    asset: 'BTC',
    tradingProductType: 'crypto',
    quantity: 10890.98959140367,
    currentPriceUsd: 241.85559140367,
    totalValueUsd: 2635677.768767545654,
    dollarCostAvg: 158.28756456,
    percentage: 52.79,
  },
  {
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
    address: '2820hd1a093837c5ea598d9ef82700552',
    asset: 'BTC',
    tradingProductType: 'crypto',
    quantity: 10890.98959140367,
    currentPriceUsd: 241.85559140367,
    totalValueUsd: 2635677.768767545654,
    dollarCostAvg: 158.28756456,
    percentage: -52.79,
  },
  {
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
    address: '0x4114fef341hyv65ea598d9ef82700552',
    asset: 'BTC',
    tradingProductType: 'crypto',
    quantity: 10890.98959140367,
    currentPriceUsd: 241.85559140367,
    totalValueUsd: 2635677.768767545654,
    dollarCostAvg: 158.28756456,
    percentage: 52.79,
  },
  {
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
    address: '1211111111165ea598d9ef82700552',
    asset: 'BTC',
    tradingProductType: 'crypto',
    quantity: 10890.98959140367,
    currentPriceUsd: 241.85559140367,
    totalValueUsd: 2635677.768767545654,
    dollarCostAvg: 158.28756456,
    percentage: 52.79,
  },
];

export const dummyObj = [
  {
    asset: 'BTC',
    name: 'Bitcoin',
    icon: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png',
  },
  {
    asset: 'ETH',
    name: 'Ethereum',
    icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
  },
  {
    asset: 'DOGE',
    name: 'Dogecoin',
    icon: 'https://cryptologos.cc/logos/dogecoin-doge-logo.png',
  },
];

export const performanceQuarterDummyData = [
  {
    quarterId: '2024-Q4',
    startDate: '2024-10-01',
    endDate: '2021-10-31',
    realizedPnL: 1000,
    runningPnL: 2000,
    returnRate: 0.1,
    profitableTrades: 10,
    losingTrades: 5,
    openTrades: 2,
    stakingBalance: 10000,
    earnedRewards: 100,
  },
  {
    quarterId: '2024-Q4',
    startDate: '2024-11-01',
    endDate: '2021-11-31',
    realizedPnL: 1000,
    runningPnL: 2000,
    returnRate: 0.1,
    profitableTrades: 10,
    losingTrades: 5,
    openTrades: 2,
    stakingBalance: 10000,
    earnedRewards: 100,
  },
  {
    quarterId: '2024-Q4',
    startDate: '2024-12-01',
    endDate: '2021-12-31',
    realizedPnL: 1000,
    runningPnL: 2000,
    returnRate: 0.1,
    profitableTrades: 10,
    losingTrades: 5,
    openTrades: 2,
    stakingBalance: 10000,
    earnedRewards: 100,
  },
];

export const dummyResponsePerformanceData = {
  quartersData: [
    {
      quarterId: '2024-Q4',
      startDate: '2024-10-01',
      endDate: '2024-12-31',
      isLatest: true,
      realizedPnL: 372078.32890973805,
      runningPnL: 311840.1175,
      returnRate: 0.0693,
      profitableTrades: 31,
      losingTrades: 8,
      openTrades: 59,
      stakingBalance: 10000,
      earnedRewards: 9,
    },
    {
      quarterId: '2024-Q3',
      startDate: '2024-07-01',
      endDate: '2024-09-30',
      isLatest: false,
      realizedPnL: 372078.32890973805,
      runningPnL: 311840.1175,
      returnRate: 0.0693,
      profitableTrades: 31,
      losingTrades: 8,
      openTrades: 59,
      stakingBalance: 10000,
      earnedRewards: 9,
    },
    {
      quarterId: '2024-Q2',
      startDate: '2024-04-01',
      endDate: '2024-06-30',
      isLatest: false,
      realizedPnL: 372078.32890973805,
      runningPnL: 311840.1175,
      returnRate: 0.0693,
      profitableTrades: 31,
      losingTrades: 8,
      openTrades: 59,
      stakingBalance: 10000,
      earnedRewards: 9,
    },
    {
      quarterId: '2024-Q1',
      startDate: '2024-01-01',
      endDate: '2024-03-31',
      isLatest: false,
      realizedPnL: 372078.32890973805,
      runningPnL: 311840.1175,
      returnRate: 0.0693,
      profitableTrades: 31,
      losingTrades: 8,
      openTrades: 59,
      stakingBalance: 10000,
      earnedRewards: 9,
    },
    {
      quarterId: '2023-Q4',
      startDate: '2023-10-01',
      endDate: '2023-12-31',
      isLatest: false,
      realizedPnL: 372078.32890973805,
      runningPnL: 311840.1175,
      returnRate: 0.0693,
      profitableTrades: 31,
      losingTrades: 8,
      openTrades: 59,
      stakingBalance: 10000,
      earnedRewards: 9,
    },
  ],
  performanceData: [
    {
      quarterId: '2024-Q4',
      startDate: '2024-10-01',
      endDate: '2021-10-31',
      realizedPnL: 1000,
      runningPnL: 2000,
      returnRate: 0.1,
      profitableTrades: 10,
      losingTrades: 5,
      openTrades: 2,
      stakingBalance: 10000,
      earnedRewards: 100,
    },
    {
      quarterId: '2024-Q4',
      startDate: '2024-11-01',
      endDate: '2021-11-31',
      realizedPnL: 1000,
      runningPnL: 2000,
      returnRate: 0.1,
      profitableTrades: 10,
      losingTrades: 5,
      openTrades: 2,
      stakingBalance: 10000,
      earnedRewards: 100,
    },
    {
      quarterId: '2024-Q4',
      startDate: '2024-12-01',
      endDate: '2021-12-31',
      realizedPnL: 1000,
      runningPnL: 2000,
      returnRate: 0.1,
      profitableTrades: 10,
      losingTrades: 5,
      openTrades: 2,
      stakingBalance: 10000,
      earnedRewards: 100,
    },
    {
      quarterId: '2024-Q3',
      startDate: '2024-07-01',
      endDate: '2024-07-31',
      realizedPnL: 1000,
      runningPnL: 2000,
      returnRate: 0.1,
      profitableTrades: 10,
      losingTrades: 5,
      openTrades: 2,
      stakingBalance: 10000,
      earnedRewards: 100,
    },
    {
      quarterId: '2024-Q3',
      startDate: '2024-08-01',
      endDate: '2024-08-31',
      realizedPnL: 1000,
      runningPnL: 2000,
      returnRate: 0.1,
      profitableTrades: 10,
      losingTrades: 5,
      openTrades: 2,
      stakingBalance: 10000,
      earnedRewards: 100,
    },
    {
      quarterId: '2024-Q3',
      startDate: '2024-09-01',
      endDate: '2024-09-31',
      realizedPnL: 1000,
      runningPnL: 2000,
      returnRate: 0.1,
      profitableTrades: 10,
      losingTrades: 5,
      openTrades: 2,
      stakingBalance: 10000,
      earnedRewards: 100,
    },
  ],
};

export const dummyArraySplitPnlChart = [
  {
    date: '2025-01-06',
    value: 804668.5732,
    lossValue: null,
    profitValue: null,
  },
  {
    date: '2025-01-07',
    value: 573765.0478,
    lossValue: '-154199.978',
    profitValue: '727965.0259',
  },
  {
    date: '2025-01-08',
    value: 467737.8553,
    lossValue: '-178318.9031',
    profitValue: '646056.7584',
  },
  {
    date: '2025-01-09',
    value: 321032.132,
    lossValue: '-222223.5493',
    profitValue: '543255.6813',
  },
  {
    date: '2025-01-10',
    value: 422952.966,
    lossValue: '-201365.859',
    profitValue: '624318.825',
  },
  {
    date: '2025-01-11',
    value: 409449.8619,
    lossValue: '-188373.1491',
    profitValue: '597823.011',
  },
  {
    date: '2025-01-12',
    value: 324277.4425,
    lossValue: '-214490.2536',
    profitValue: '538767.6961',
  },
  {
    date: '2025-01-13',
    value: 277489.0548,
    lossValue: '-226313.4934',
    profitValue: '503802.5482',
  },
  {
    date: '2025-01-14',
    value: 338517.4747,
    lossValue: '-200763.9816',
    profitValue: '539281.4563',
  },
  {
    date: '2025-01-15',
    value: 596887.5263,
    lossValue: '-114290.193',
    profitValue: '711177.7194',
  },
  {
    date: '2025-01-16',
    value: 644878.0856,
    lossValue: '-105545.7098',
    profitValue: '750423.7954',
  },
  {
    date: '2025-01-17',
    value: 781750.2633,
    lossValue: '-80690.7672',
    profitValue: '862441.0305',
  },
  {
    date: '2025-01-18',
    value: 814813.3281,
    lossValue: '-41735.7602',
    profitValue: '856549.0883',
  },
  {
    date: '2025-01-19',
    value: 417540.0688,
    lossValue: '-122437.4975',
    profitValue: '539977.5664',
  },
  {
    date: '2025-01-20',
    value: 463855.1317,
    lossValue: '-94833.2575',
    profitValue: '558688.3892',
  },
  {
    date: '2025-01-21',
    value: 558966.4808,
    lossValue: '-59924.0727',
    profitValue: '618890.5535',
  },
  {
    date: '2025-01-22',
    value: 574916.7375,
    lossValue: '-68577.0274',
    profitValue: '643493.7649',
  },
  {
    date: '2025-01-23',
    value: 424633.5913,
    lossValue: '-111504.9747',
    profitValue: '536138.5659',
  },
  {
    date: '2025-01-24',
    value: 458073.757,
    lossValue: '-98581.2078',
    profitValue: '556654.9648',
  },
  {
    date: '2025-01-25',
    value: 464749.1163,
    lossValue: '-101787.9863',
    profitValue: '566537.1026',
  },
  {
    date: '2025-01-26',
    value: 366350.1189,
    lossValue: '-134720.9447',
    profitValue: '501071.0636',
  },
  {
    date: '2025-01-27',
    value: 117359.5009,
    lossValue: '-161606.6078',
    profitValue: '278966.1087',
  },
  {
    date: '2025-01-28',
    value: -21542.2177,
    lossValue: '-228208.7301',
    profitValue: '206666.5124',
  },
  {
    date: '2025-01-29',
    value: 60630.8772,
    lossValue: '-181044.0006',
    profitValue: '241674.8777',
  },
  {
    date: '2025-01-30',
    value: 141553.6716,
    lossValue: '-114052.7',
    profitValue: '255606.3716',
  },
  {
    date: '2025-01-31',
    value: 39081.7008,
    lossValue: '-181660.6353',
    profitValue: '220742.3361',
  },
  {
    date: '2025-02-01',
    value: -166445.5341,
    lossValue: '-305662.2451',
    profitValue: '139216.711',
  },
  {
    date: '2025-02-02',
    value: -468286.3166,
    lossValue: '-554539.5976',
    profitValue: '86253.281',
  },
  {
    date: '2025-02-03',
    value: -213647.0114,
    lossValue: '-335404.8006',
    profitValue: '121757.7892',
  },
  {
    date: '2025-02-04',
    value: -370546.3602,
    lossValue: '-460526.6905',
    profitValue: '89980.3303',
  },
  {
    date: '2025-02-05',
    value: -372442.1401,
    lossValue: '-462714.3786',
    profitValue: '90272.2385',
  },
];
